.expenses-table-section {
  display: flex;
  column-gap: 10px;
}

.expenses-table-section .table-map-expenses {
  width: 100%;
}
.expense-report-filter {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.expense-report-filter > * {
  max-width: 300px;
}

@media only screen and (max-width: 1742px) {
  .expense-report-filter > * {
    width: 100%;
    justify-content: start;
  }
  .expense-report-filter {
    justify-content: flex-start;
  }
}
