#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-content-wrapper {
  width: 100%;
  padding-top: 80px;
}
@media screen and (max-width: 960px) {
  .main-content-wrapper {
    /* width: 100%; */
    padding-top: 220px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Toastify__toast-body > div:last-child {
  word-break: normal !important;
}
.Toastify__close-button {
  margin: 0 0;
  width: 10%;
}
.user-not-authenticated .MuiDialog-paper {
  width: 400px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.account-table{
  display:flex;
  flex-direction: row;
  /* margin: 20px; */
  width: 100%;
  justify-content: space-around;
}
.account-filter{
  display:flex;
  flex-direction: row;
  justify-content: center;
align-items: center;
  
}



/* Test */

.table {
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
  margin:10px;
}

.table th {
  margin:10px;
  background-color: #f2f2f2;
}