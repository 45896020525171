:root{
    --box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    --primary-bg-color: #2196f3;
    --box-shadow-red: rgba(255, 48, 0, 0.8) 0px 1px 3px 0px,
    rgba(255, 48, 0, 0.8) 0px 0px 0px 1px;
    --box-shadow-green: rgba(41, 132, 0, 0.8) 0px 1px 3px 0px,
    rgba(41, 132, 0, 0.8) 0px 0px 0px 1px;
    --mui-border-radius: 4px;
    --mui-border-color: rgba(0, 0, 0, 0.23);
    --mui-chip-color: rgba(0, 0, 0, 0.12);
}

@import './styles.css';
@import './_variables.css';
@import './_remarks.css';
@import './_highchart.css';
@import './_loader.css';
@import './_table.css';
@import './component/_dir.css';
@import './_tab.css';
@import './_stack.css';
@import './_access_denied.css';
@import './_cell_edit_component.css';
@import './client.css';
@import './_divider.css';