.custom-stack-layout{
    display: flex;
    flex-direction: column-reverse !important;
    gap: 10px;
    background-color: var(--primary-bg-color) !important;
    border-radius: 6px !important;
}


.custom-stack-layout div > * {
    margin: 0px !important;
}

.custom-stack-layout > div {
    display: flex;
    gap: 10px;
}


@media only screen and (max-width: 771px){
    .custom-stack-layout > div {
        flex-direction: column-reverse;
    }
}