@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  width: 100%;
  height: 100vh;
  background-color: #0f1123;
  display: flex;
  justify-content: center;
}
/* 
button {
    margin-top: 50px; 
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }
 */
.card {
  width: 100%;
  margin-bottom: 10px;
  /* transform: scale(1.2); */
  /* justify-content: space-evenly;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  position: relative;
  display: grid;
  gap: 0 20px; */
}

 .card-item {
  background-color: #fff;
  width: 100%;
  height: 400px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  /* z-index: 10; */
}
.card-item img {
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in;
  border-radius: 10px;
  aspect-ratio: 1;
  box-shadow: 0px -100px 90px 10px grey inset;
}
.card-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  transition: 0.5s ease-in-out;
  color: #fff;
  padding: 10px;
}

.card-content p {
  color: #cecece;
  transition: 0.5s ease-in-out;
}

.card-content .info {
  display: none;
  transition: 0.5s ease-in-out;
}

.card-item:hover img {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.card-item:hover .card-content {
  color: #000;
  top: 10px;
  left: 10px;
}
.card-item:hover p {
  color: #313131;
 
}
.card-item:hover .info {
  display: block;
  
}
.card-item:hover{
  cursor: pointer;
  /* transform: scale(1.1); */

}

.card:hover{
  cursor: pointer;
}

 .details-container p:hover{
  /* color: white !important; */
}

/* .card-item:hover + .details-container{
  display: flex;
}  */