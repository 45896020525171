.remark-section {
  /* border: 1px solid black; */
  min-height: 60vh;
  border-radius: 14px 14px 14px 14px;
  margin: 10px 0px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.remark-section ul {
  height: calc(60vh - 30px);
  list-style: none;
  padding: 5px 15px;
  margin: 10px 0px;
  overflow: hidden;
  overflow-y: scroll;
}

.remark-section .remark-title {
  box-shadow: var(--box-shadow);
  background-color: var(--mui-chip-color);
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
}

.remark-section .inputfield-btn {
  position: relative;
  /* border: 1px solid black; */
}

.remark-section .inputfield-btn .btn {
  position: absolute;
  top: 25%;
  right: 0;
}

.remark-section .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 14px;
    margin: 5px 10px;
}

.remark-section ul li {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.remark-section .user-remark{
    background-color: var(--mui-chip-color);
    padding: 10px 20px;
    border-radius: 14px;
}

.remark-section .user-details{
    font-size: small;
    display: flex;
    justify-content: center;
    
}
/* .remark-section ul .other-user {

} */

