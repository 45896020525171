.appointment-modal div[style*="overflow-y: hidden;"] {
    overflow: auto !important;
}

.dialog-select-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rbc-btn-group {
    white-space: normal;
    /* width: 10vw; */
}

.rbc-toolbar button {
    font-size: 13px;
}

.rbc-time-view .rbc-row {
    /* min-height: 23px; */
}

.Calendar {
    background: white;
}

.clear-icon:hover {
    cursor: pointer;
}

.timepicker-container {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.timepicker-container div * {
    width: 100% !important;
}

.dialog-textfield {
    width: 100% !important;
    margin-top: 10px;
}

/* .css-19kzrtu{
    width: 80%;
    margin: 0 auto;

} */

.appointment-modal .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin-top: 10px;
}

.appointment-modal .css-jh47zj-MuiButtonBase-root-MuiButton-root {
    padding: 8px 65px;
}

.refreshment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 5px; */
}
/*
.rbc-responsive .rbc-agenda-view,
.rbc-responsive .rbc-month-view {
  display: none;
}

.rbc-responsive .rbc-day-view,
.rbc-responsive .rbc-week-view {
  display: block;
} */
/* div#Calendar .rbc-month-view {
    display: block !important;
} */

div#Calendar .rbc-month-row + .rbc-month-row {
    height: 110px;
}

.calendar-booking-slot .rbc-day-slot .rbc-events-container {
    margin-right: 0;
}

@media screen and (max-width: 768px) {
    .appointment-modal{
        z-index: 100 !important;
    }
    .booking_appointment-modal{
        font-size: 15px !important;
        font-weight: 800 !important;
    }
    .refreshment{
        flex-direction: column;
        align-items: baseline;
    }
    .error{
        font-size: 12px !important;
    }
    .rbc-toolbar{
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

/* .rbc-calendar {
    overflow: hidden !important;
  }

  .rbc-week-view {
    overflow: hidden !important;
  } */
  .calendar-booking-slot {
    height: 400px;
        overflow: visible !important;
      
  }
  #Ca
  
  lendar .rbc-time-content {
    max-height: 400px;
    }