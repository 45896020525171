*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
 
    background-color: white;

}
 .background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
} 
.otp-container{
  margin-top: 20px;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
}

.login-domain{
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.login-container{
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
}

/* Select::label{
  color: black !important;
} */
/* .background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
} */
/* .shape:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}
.shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
} */
.form-login {
  /* border: 1px solid red; */
    margin-top: 50px;
    height: 24rem;
    width: 40rem;
    /* background-color: #f2f2f2; */
    border-radius: 10px;
    /* box-shadow: 0 0 40px rgba(8, 7, 16, 0.6); */
    padding: 50px 35px; 
    border: 1px solid #5d5d5d;

}


.form-login h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

/* label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
} */
/* input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
} */

/* select {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
  } */

/* select:active{
    color:black
} */

input::placeholder {
  color: black;
  font-weight: 500;
}
/* button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
} */
/* button:hover{
  background-color: #d8d4d4;
} */
.social {
  margin-top: 30px;
  display: flex;
}
.social div {
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
}
.social div:hover {
  background-color: rgba(255, 255, 255, 0.47);
}
.social .fb {
  margin-left: 25px;
}
.social i {
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .form-login{
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
  }
}