/* .primary-tab-color {

} */

.MuiTabs-indicator{
    color: var(--primary-bg-color) !important;
    background-color: var(--primary-bg-color) !important;
}

.Mui-selected{
    color: var(--primary-bg-color) !important;
}