.text-dark{
    text-decoration: none;
    font-weight: 300;
    color: black;

}

.p-3{
    
    padding: 0.5rem 1rem;
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    background-color: #E0E0E0 !important;
    border-top: 1px solid #eee;
}

.text-center{
    margin-top: auto;
}