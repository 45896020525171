/* .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  height: 70px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  margin-left: auto;
  position: absolute;
  margin-right: auto;
}
.logo:hover {
  cursor: pointer;
}

.main_logo:hover {
  cursor: pointer;
}
.logo-container .logo:nth-of-type(1) {
  max-height: inherit;
  max-width: 50px;
}

.logo-container .logo:nth-of-type(2) {
  max-height: inherit;
  max-width: 120px;
}

.logo-container .logo:nth-of-type(3) {
  max-height: inherit;
  max-width: 70px;
}

.logo {
  margin-right: 2rem;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar:hover {
  cursor: pointer;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: initial;
  position: absolute;
  right: 0;
}

.avatar-container:hover {
  cursor: pointer;
}

.logout-container {
  position: absolute;
  right: 0;
  top: calc(100% + 17px);
  background: white;
  width: 20em;
}

.logout-container::after {
  content: "";
  bottom: calc(100% - 1px);
  left: 6px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #ddd transparent;
  transform: rotate(0deg);
}

.logout-button {
  margin-top: 0;
  padding: 5px;
  text-align: center;
}

.timepicker-container div * {
  width: 100% !important;
}

.menu-content {
  position: absolute;
  bottom: 0;
  left: -166px;
  background-color: #e5e0e0;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
}

.menu-content ul {
  list-style-type: none;
  padding: 0;
  font-size: 13px;
  text-align: center;
}

.menu-content li {
  padding: 0 0;
}

.menu-container:hover .menu-content {
  transform: translateY(0%);
}

.list-item-two:hover {
  cursor: pointer;
}

.logout-button {
  margin-top: 0;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.logout-button:hover {
  cursor: pointer;

  background-color: #ddd;
  color: white;
}

.main_logo {
  max-width: 250px;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;

    height: 220px;
    justify-content: space-between;
  }
  .avatar-container {
    flex-direction: column;
    margin: 0 auto;
    position: static;
  }
  .logo-container .logo:nth-of-type(1) {
    max-height: inherit;
    max-width: 90px;
  }

  .logo-container .logo:nth-of-type(2) {
    max-height: inherit;
    max-width: 150px;
  }

  .logo-container .logo:nth-of-type(3) {
    max-height: inherit;
    max-width: 120px;
  }
  .main_logo {
    position: static;
  }
  .logo-container {
    position: static;
  }
}
@media screen and (max-width: 450px) {
  .logo-container .logo:nth-of-type(1) {
    max-height: inherit;
    max-width: 70px;
  }

  .logo-container .logo:nth-of-type(2) {
    max-height: inherit;
    max-width: 120px;
  }

  .logo-container .logo:nth-of-type(3) {
    max-height: inherit;
    max-width: 100px;
  }
} */

.navbar{
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  width: 100%;
  z-index: 58;
  height: 70px;
}

.navbar .main_logo {
  max-width: 250px;
  left: 0;
  cursor: pointer;
}

.navbar .logo-container{
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.navbar .logo-container > img {
  max-width: inherit;
  max-height: 50px
}

.avatar-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.navbar .details {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logout-button {
  margin-top: 0;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.logout-button:hover {
  cursor: pointer;

  background-color: #ddd;
  color: white;
}

.logout-container {
  position: absolute;
  right: 10px;
  top: calc(100% + 17px);
  background: white;
  width: 20em;
  cursor: pointer;
}

.logout-container::after {
  content: "";
  bottom: calc(100% - 1px);
  left: 6px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #ddd transparent;
  transform: rotate(0deg);
}

.logout-button {
  margin-top: 0;
  padding: 5px;
  text-align: center;
}

.timepicker-container div * {
  width: 100% !important;
}

.avatar-container select {
  border: none;
  background: none;
  box-shadow: var(--box-shadow);
  background-color: white;
}



@media screen and (max-width: 960px){
  .navbar{
    flex-direction: column;
    height: 220px;
  }

  .avatar-container{
    flex-direction: column;
  }
}