.back-button{
    border: 1px solid black;
    border-radius: 50%;
    /* border: 1px solid black; */
    
    margin-left: 20px;
}

.back-button:hover{
    cursor: pointer;
    background-color: rgb(212, 209, 209);
}

@media screen and (max-width: 450px) {
.booking-tabs button{
    padding: 12px 5px;
   
    font-size: 12px;
}
}

body.no-scroll {
    overflow: hidden;
    }