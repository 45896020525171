.approve-meeting-detail {
    border: 1px solid #5d5d5d;
    width: 100%;
    border-radius: 10px;
    margin: 0 auto;
    padding: 30px;
    font-size: 16px;
    
}

.approve_meeting_button-container {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}


.approve-meeting-detail li {
    padding-bottom: 15px;
    list-style: none;   
    color: #2b2b2b;
}

.approve-meeting-detail li strong {
    min-width: 230px;
    display: inline-flex;
  }

.approve-meeting-container h1 {
    padding-bottom: 10px;   
}

@media screen and (max-width: 768px) {
    .approve-meeting-container h1{
        text-align: center;
    }

    .approve-meeting-detail li strong{
        font-size: 13px;
        min-width: 190px;
    }
}
