.access_denied{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    min-height: 50vh;
}

.access_denied svg{
    font-size: 5rem;
    color: lightgray;
}

.access_denied span:nth-of-type(1){
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 2px;
}

.access_denied span:nth-of-type(2){
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}