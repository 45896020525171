/* .edit-component input {
  position: fixed;
  top: 0;
  width: 0px;
  height: 0px;
  opacity: 0;
}


.edit-component .edit-select{
  border-radius: 16px;
}

.cell-value {
    padding: 6px 20px;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    background-color: rgba(154, 209, 111, 0.8);
    color: white;
    cursor: pointer;
  }
  
  .editCell {
    min-width: 150px;
  }
  
  .edit-icon {
    font-size: initial;
    padding: 1px 3px;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    cursor: pointer;
  }
  
  .edit-component {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    position: relative;
  }
  
  .update-btn {
    font-size: small;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 5px 8px;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    transition: 0.7s ease-in-out;
    border: none;
    background-color: none;
  }
  .enable-edit-cell-false,
  .inactive-edit-icon,
  .inactive-select-options,
  .inactive-update-btn {
    display: none !important;
  } */


  .edit-component{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .inactive-toggle{
    height: 0px;
    top: 0px;
  }

  .edit-component input {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 0;
  }

  .child-edit-component {
    background-color: white;
    width: 50%;
    border-radius: 14px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }

 .dialog-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    padding: 20px 20px;
    margin: 0px 0px 10px 0px;
    font-size: 1.5rem;
    font-weight: 600;
  }

 .dialog-title button{
    border: none;
    outline: none;
    background: none;
    appearance: none;
    color: none;
    cursor: pointer;
  }

 .form-edit{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px;
  }

  .close-icon-span{
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .close-icon-span .custom-close-icon {
    color: red;
  }

  .edit-status {
    padding: 6px 20px;
    border-radius: 16px;
    box-sizing: border-box;
    /* color: white; */
    cursor: pointer;
  }
  
  
  
  .element-complete{
    color: green;
    box-shadow: var(--box-shadow-green);
  }
  
  .element-pending{
    color: red;
    box-shadow: var(--box-shadow-red);
  }