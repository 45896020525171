.w-100 {
  width: 100% !important;
}

input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* fieldset legend span {
  padding: 0px !important;
  white-space: nowrap !important;
  width: initial !important;
  display: inline-block !important;
  margin-right: -4px !important;
} */

.dialog-title-sh {
  box-shadow: var(--box-shadow);
}

h2 {
  font-size: 20px;
}

.p-title {
  font-size: small;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  padding: 5px 10px;
  border-radius: 14px;
}

.default-span {
  padding: 5px 10px;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
}

.divider-margin {
  margin: 10px 0px !important;
}

.primary-bg-color {
  background-color: var(--primary-bg-color);
}

.wip {
  background-color: #fdba74 !important;
}

.closed {
  background-color: #9c3b45 !important;
}

.converted {
  background-color: #86efac !important;
}

.inactive-row {
  background-color: #e68e97 !important;
}

/* status */

.warning {
  background-color: #fdba74 !important;
}

.error {
  background-color: #9c3b45 !important;
}

.success {
  background-color: #86efac !important;
}


button {
  text-wrap: nowrap;
  /* padding: 0px 10px !important; */
}

.border-radius {
  border-radius: 10px !important;
}

.MuiBox-root {
  padding: 0px !important;
}


.close-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(255, 0, 0);
}

.close-icon svg {
  color: white;
}

.box-border-global {
  box-shadow: var(--box-shadow) !important;
  padding: 10px !important;
  border-radius: 3px !important;
  margin: 0px 0px 10px 0px !important;
}

.box-shadow {
  box-shadow: var(--box-shadow);
}

.date-box{
  position: relative;
  font-size: 10px;
  
  padding: 5px 11px;  
}

.date-box:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: orange; */
  border: 1px solid var(--mui-border-color);
  border-radius: 14px;
}
 

.p-16{
  padding: 16px;
}
.p-12{
  padding: 12px;
}

.mui-border{
  border: 1px solid var(--mui-border-color);
  border-radius: var(--mui-border-radius);
}

.small-span{
  font-size: 0.8rem;
  box-shadow: 0px 0px 1px black;
  padding: 5px 13px;
  border-radius: 14px;
  margin: 0px 10px;
}


.form-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
}