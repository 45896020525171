.table-custom-style {
  position: relative;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.table-custom-style .table-export-btn {
  position: absolute;
  right: 0px;
  margin: 6px 10px 0px 0px;
  border: none;
  background: none;
  cursor: pointer;
}

.table-custom-style .table-export-btn svg{
    color: white;
}

.table-custom-style thead th {
  background-color: var(--primary-bg-color);
  color: white;
  padding: 6px 16px;
  text-wrap: nowrap;
}

.table-custom-style td {
  padding: 6px 16px;
  text-wrap: nowrap;
}

.table-custom-style tbody :nth-child(even) td {
  background-color: rgba(234, 234, 234, 0.8);
}

.table-custom-style tbody :nth-child(odd) td {
  background-color: rgba(255, 255, 255, 0.8)
}

.table-custom-style tfoot td {
  font-size: initial;
  font-weight: 800;
  text-wrap: nowrap;
}


.table-custom-style tbody tr:hover{
  background-color: rgba(175, 172, 174, 0.8) !important;
  transition: .2s ease-in-out;
}

.custom-table-header thead th {
  background-color: var(--primary-bg-color) !important;
  color: white !important;
  padding: 6px 16px;
  text-wrap: nowrap;
}