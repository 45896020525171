:root {
    --highchart-tool-bar-height: 40px;
}

.highchart-custom-container {
  position: relative;
  box-shadow: var(--box-shadow);
  margin: 10px 0px;
  padding: 0px 10px 10px 10px;
  border-radius: 14px;
  overflow: clip;
}

.highchart-custom-container .highchart-btns {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  left: 0;
  width: 100%;
  height: var(--highchart-tool-bar-height);
}

.highchart-btns label{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-shadow: var(--box-shadow);
    padding: 0px 20px;
    cursor: pointer;
}

.highchart-btns fieldset{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.highchart-btns input[type="radio"], 
.highchart-btns input[type="checkbox"]{
    display: none;
}
.highchart-btns input[type="checkbox"]:checked + label,
.highchart-btns input[type="radio"]:checked + label {
    background-color: rgba(226, 226, 226, 0.8);
    transition: 0.5s ease-in-out;
}

.highchart-content{
    margin-top: calc(var(--highchart-tool-bar-height) + 5px);
    min-height: 400px;
}

.bar-icon{
    transform: rotate(90deg);
}